body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 300;
  background-color: #FFFAE5;
  min-width: 320px;
  position: relative;
  margin: 0;
  color: #584E3B;
}

#game-container {
  width: 320px;
  margin: 0 auto;
}

.titleBlock {
  width: 320px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: flex-end;
}

.title {
  font-family: "Lora", serif;
  font-size: 24px;
  color: #584E3B;
  flex: 1 auto;
}

.logo { 
  width: 131px;
  text-align: center;
}

.small {
  font-size: 10px;
  margin: 0 auto;
}

#game-board {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(10, 1fr);
  gap: 2px;
  padding: 5px;
}

.cell {
  width: 35px;
  height: 35px;
  border: 1px solid #D9D9D9;
  border-radius: 40px;
  background-color: #D9D9D9;
  font-family: "Baloo 2", Arial, sans-serif;
  color: #584E3B;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dailyChains {
  background-color: #ffffff;
  border-radius: 165px;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 12px;
  display: flex;
  align-content: center;

  .cols {
    margin-left: 10px;
    margin-right: 10px;

    img {
      margin: 0 auto;
    }
  }

  .para {
    margin: 0;
    line-height: 18px;
  }
}

#button-container {
  text-align: center;
  margin: 20px auto 0;
  position: relative;

  .game-buttons {
    display: inline-flex;
    width: 180px;
  }

  .back, .restart {
    margin: 0 5px;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    border: 2px solid #F5CB5C;
    background-color: #ffffff;
    position: relative;
    display: flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .back {
    background: #ffffff url(/public/images/back_icon.png) 17px 17px no-repeat;
    background-size: 10px;
    position: relative;
    text-align: center;
    padding: 0;

    .label {
      padding-top: 60px;
      text-align: center;
      display: block;
      margin: 0 auto;
      font-family: "Open Sans", Arial, Helvetica, sans-serif;
      font-size: 12px;
      font-weight: 300;
      color: #584E3B;
    }
  }

  .restart {
    background: #ffffff url(/public/images/restart_icon.png) 6px 5px no-repeat;
    background-size: 35px;
    position: relative;
    text-align: center;
    padding: 0;

    .label {
      padding-top: 60px;
      text-align: center;
      display: block;
      margin: 0 auto;
      font-family: "Open Sans", Arial, Helvetica, sans-serif;
      font-size: 12px;
      font-weight: 300;
      color: #584E3B;
    }
  }

  .steps {
    margin: 0 5px;
    border-radius: 50px;
    border: 2px solid #584E3B;
    background-color: #ffffff;
    position: relative;
    height: 46px;
    width: 46px;
    text-align: center;
    padding: 0;

    .counter{
      display: block;
      font-family: "Baloo 2", Arial, sans-serif;
      font-weight: bold;
      font-size: 22px;
      line-height: 0;
      color: #584E3B;
      padding-top: 24px;
    }
  
    .label {
      display: block;
      padding-top: 36px;
      font-family: "Open Sans", Arial, Helvetica, sans-serif;
      font-size: 12px;
      font-weight: 300;
      color: #584E3B;
    }
  }

  .help {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    text-indent: -20000px;
    position: absolute;
    right: 20px;
    top: 10px;
    background: #F5CB5C url(/public/images/help_icon.png) 10px 7px no-repeat;
    background-size: 10px;
  }
}

.attempts {
  margin: 30px 60px;
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 2px;
  text-align: center;
}

#target-display {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

#scoreboard {
  width: 100%;
  margin-top: 20px;
  background-color: #ffffff;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 12px;
}

#scoreboard th {
  font-family: "Lora", Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 10px;
  color: #584E3B;
}
#scoreboard td {
  padding: 10px;
  text-align: center;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  color: #584E3B;
}

.statistics{
  margin-bottom: 50px;
}

h2 {
  font-family: "Lora", Arial, Helvetica, sans-serif;
  font-size: 24px;
  text-align: center;
  border-bottom: 3px solid #F5CB5C;
  width: auto;
  display: inline-block;
  padding-bottom: 10px;
  margin-top: 20px;
}

.howto, .about {
  padding: 5px 20px;
  text-align: center;

  p {
    text-align: left;
  }

  .last-para {
    margin-bottom: 0;
  }
}

.howto {
  padding: 5px 0;
}

.about {
  background-color: #ffffff;
  border-radius: 12px;

  .last-para {
    text-align: center;
    margin-bottom: 20px;
  }

  .web:link, .web:visited {
    margin-bottom: 20px;
    text-align: center;
    font-family: "Lora", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #584E3B;
    text-decoration: none;
  }

  .web:hover{
    text-decoration: underline;
  }
}

.spacer {
  margin: 50px auto;
  text-align: center;
  padding: 0;
  display: block;
  height: 30px;
}

.footer {
  margin: 50px auto;
  font-size: 10px;
  text-align: center;
}